import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject, first } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthUser } from '../model/auth-user';
import { LoginError } from '../model/login-error';
import { Client } from '../model/client';
import { User } from '../model/user';
import { RegisterResponse } from '../model/register-response';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _isLoggedIn = new BehaviorSubject<boolean>(false);
  private loginError = new Subject<string>();
  private _user: AuthUser | null = null;

  loginError$ = this.loginError.asObservable();
  isLoggedIn$ = this._isLoggedIn.asObservable();

  router = inject(Router);
  private http = inject(HttpClient);

  get isLoggedIn(): BehaviorSubject<boolean> {
    return this._isLoggedIn;
  }

  get user(): AuthUser | null {
    return this._user;
  }

  updateClient(client: Client) {
    this.http.put<Client>(
      `${environment.apiUrl}/clients/${this.user?.id}`,
      client)
      .subscribe(response => {
        this._user = {
          ...response,
          session: this._user?.session
        }
      });
  }

  checkSession(): boolean {
    if (sessionStorage.getItem('_cln')) {
      let user: AuthUser = JSON.parse(sessionStorage.getItem('_cln') || '');
      if (user) {
        this._user = user;
        return true;
      }
    }

    return false;
  }

  login(loginObj: any) {
    this.http.post<AuthUser | LoginError>(
      `${environment.apiUrl}/clin`,
      { login: loginObj.login, password: loginObj.password }).pipe(first())
      .subscribe(response => {
        if (!('is_error' in response)) {
          this._user = response;
          sessionStorage.setItem('_cln', JSON.stringify(this._user));
          this._isLoggedIn.next(true);
          this.loginError.next("");
        } else {
          this._isLoggedIn.next(false);
          this.loginError.next(response.content);
        }
      });
  }

  logout() {
    sessionStorage.removeItem('_cln');
    this._user = null;
    this._isLoggedIn.next(false);
    this.router.navigateByUrl("login");
  }

  createUser(user: User): Observable<RegisterResponse> {
    return this.http.post<RegisterResponse>(
      `${environment.apiUrl}/register`,
      user)
  }

  forgotPassword(email: string) {
    return this.http.post<string>(
      `${environment.apiUrl}/pwreset`,
      {
        email: email,
        source: 'subscriptions'
      }
    )
  }

  resetPassword(code: string, password: string) {
    return this.http.post<string>(
      `${environment.apiUrl}/pwreset`,
      {
        validation_code: code,
        password: password
      }
    )
  }

  verifyEmail(sid: string, cid: string, code: string) {
    return this.http.post<string>(
      `${environment.apiUrl}/validate`,
      {
        s: sid,
        n: cid,
        c: code,
      }
    )
  }
}
